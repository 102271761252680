import React from 'react'

const WebsiteCard = ({images, title, body,  link}) => {
  return (
    <a href={link} target='_blank' rel='noreferrer' className="w-96 h-96">
      <div className="w-full h-52 bg-primary">
        <img className="object-cover w-full h-full hover:scale-105 duration-200" src={images} alt="" />
      </div>
      <div className="my-5 m-1 text-start w-full">
        <h1 className="uppercase font-bold tracking-widest">{title}</h1>
        <p className="text-xs mt-2 tracking-widest">{body}</p>
      </div>
    </a>
  );
}

export default WebsiteCard
