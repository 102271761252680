import React from "react";
import { Link, useLocation } from "react-router-dom";

const PortfolioNavigation = () => {
  const location = useLocation();

  return (
    <div>
      <h1 className="uppercase relative text-4xl tracking-wider leading-relaxed font-light">
        what project have we accomplished so far <br />
        <span className="w-44 h-52 rounded-full blur-3xl z-40 right-0 animate-spin top-20 opacity-50 bg-blend-overlay bg-Secondary absolute" />
        <span className="w-1/4 h-8 -z-40 bg-Secondary absolute" />
        <span className="font-bold">in our journey.</span>
      </h1>
      <nav className="my-8 space-x-5 font-light text-xl uppercase tracking-widest">
        <Link
          to={"/Portfolio/websites"}
          className={
            location.pathname === "/Portfolio/websites"
              ? "font-bold relative transition-all duration-500"
              : "text-sm transition-all duration-500"
          }
          href="#"
        >
          Websites{" "}
          {location.pathname === "/Portfolio/websites" && (
            <span className="absolute w-1/2 left-0 top-8 h-1 bg-Dark-primary" />
          )}
        </Link>
        <Link
          to={"/Portfolio/graphics"}
          className={
            location.pathname === "/Portfolio/graphics"
              ? "font-bold relative transition-all duration-500"
              : "text-sm transition-all duration-500"
          }
          href="#"
        >
          Graphics{""}
          {location.pathname === "/Portfolio/graphics" && (
            <span className="absolute w-1/2 left-0 top-8 h-1 bg-Dark-primary" />
          )}
        </Link>
      </nav>
    </div>
  );
};

export default PortfolioNavigation;
