import React, { useEffect } from 'react'
import WebsiteCard from '../../Component/WebsiteCard/WebsiteCard'
import PortfolioNavigation from '../../Component/PortfolioNavigation/PortfolioNavigation';
import { websites } from '../../data/website_portfolio';

const Websites = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <div className="my-32 px-12">
      <PortfolioNavigation />
      <div className="flex flex-wrap gap-8">
        {websites.map((website) => (
          <>
            <WebsiteCard
              images={website.image}
              title={website.name}
              body={website.description}
              link={website.url}
            />
          </>
        ))}
      </div>
    </div>
  );
}

export default Websites
