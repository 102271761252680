import React, { useEffect, useRef, useState } from "react";
import logo from "../../Assets/logo/black logo only.png";
import { HiMenuAlt3 } from "react-icons/hi";
import { LuPhoneCall } from "react-icons/lu";
import { HiOutlineMailOpen } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { Link } from "react-scroll";
import { Link as Link1  } from "react-router-dom";

const Navigation = () => {
  const [Showlink, setShowlink] = useState(false);
  const [Showcall, setShowcall] = useState(false);

  const togglelink = () => {
    setShowlink((prevShowlink) => !prevShowlink);
    setShowcall(false);
  };

  const togglecontact = () => {
    setShowcall((prevShowcall) => !prevShowcall);
    setShowlink(false);
  };

  const nav = [
    { link: "HOME", border: "border", to: "Home" },
    { link: "PORTFOLIO", border: "border", to: "Portfolio" },
    { link: "WHY CHOOSE US", border: "border", to: "Whychoose" },
    { link: "SERVICE", border: "border", to: "Service" },
    { link: "ABOUT US", border: "border-none", to: "About us" },
  ];

const contact = [
  {
    type: "phone",
    icon: LuPhoneCall,
    contact: "+2519 94 24 24 98",
    link: "+2519 94 24 24 98",
  },
  {
    type: "email",
    icon: HiOutlineMailOpen,
    contact: "tilettech@gmail.com",
    link_2: "mailto:tilettech@gmail.com",
  },
];

  const navigationRef = useRef(null);

  useEffect(() => {
    const closeSections = (event) => {
      if (!navigationRef.current.contains(event.target)) {
        setShowlink(false);
        setShowcall(false);
      }
    };

    document.addEventListener("click", closeSections);

    return () => {
      document.removeEventListener("click", closeSections);
    };
  }, []);
  return (
    <div
      className="flex flex-row justify-between items-center w-full pt-4 md:px-12 px-6"
      ref={navigationRef}
    >
      <Link1 to={"/"} className="">
        <img src={logo} alt="" className="md:w-16 w-12" />
      </Link1>
      <div className="flex flex-row justify-center items-center md:space-x-4 space-x-2">
        <button
          onClick={togglecontact}
          className="bg-primary md:px-7 px-5 md:py-2 py-1 text-white text-sm rounded-full hover:bg-Dark-primary focus:bg-Dark-primary duration-150 ease-in-out"
        >
          CONTACT US
        </button>
        {Showcall && (
          <div className="absolute right-16 z-40 mt-36 bg-Dark-primary rounded font-light text-sm text-white">
            <div className="flex flex-col justify-start items-start p-1">
              {contact.map((items, index) => (
                <a
                  href={
                    items.type === "phone"
                      ? `tel:${items.link}`
                      : items.link_2
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <div
                    key={index}
                    className="flex flex-row items-center p-2 w-full space-x-4"
                  >
                    <items.icon className="text-2xl text-Secondary text-start" />
                    <p>{items.contact}</p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
        <button
          className="bg-primary md:p-2 p-1 text-white text-xl rounded-full hover:bg-Dark-primary focus:bg-Dark-primary duration-150 ease-in-out"
          onClick={togglelink}
        >
          {Showlink ? <CgClose /> : <HiMenuAlt3 />}
        </button>
        {Showlink && (
          <div className="absolute md:right-12 right-6 md:mt-24 mt-64 bg-Dark-primary rounded font-light md:text-sm text-md text-white z-50">
            <ul className="list-none p-1 flex md:flex-row flex-col justify-center items-center">
              {nav.map((items) => (
                <div className="flex md:flex-row flex-col justify-center items-center">
                  <li className="p-2">
                    <Link
                      to={items.to}
                      smooth={true}
                      duration={1000}
                      onClick={togglelink}
                      className="hover:underline hover:text-Secondary duration-100 ease-in-out cursor-pointer"
                    >
                      {items.link}
                    </Link>
                  </li>
                  <div
                    className={`${items.border} h-4 md:visible hidden border-Secondary /`}
                  />
                </div>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navigation;
