import React, { useEffect, useState } from "react";
import logo from "../../Assets/logo/color logo only.png";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";

const ServiceListMobile = () => {
  const [desc, setdesc] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleclick = (index) => {
    setSelectedService(index);
    setdesc(true);
  };
  useEffect(() => {
    const body = document.querySelector("body");
    if (desc) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "";
    }
    return () => {
      body.style.overflow = "";
    };
  }, [desc]);
  const handleclose = () => {
    setdesc(false);
  };
  const service = [
    {
      text:
        "Captivate your audience with visually stunning designs that convey" +
        "your brand's essence. Our talented graphic designers bring" +
        "creativity and expertise to every project, ensuring your visuals" +
        "leave a lasting impression.",
      btn: "/Portfolio/graphics",
    },
    {
      text:
        "Step into the digital realm with our expert website development" +
        "services. From sleek and responsive designs to seamless user" +
        "experiences, we craft websites that reflect your brand identity and" +
        "engage your audience effectively.",
      btn: "/Portfolio/websites",
    },
    {
      text:
        "Empower your business with custom software solutions tailored to" +
        "your unique requirements. Our skilled developers leverage the latest" +
        "technologies to build robust and scalable software applications that" +
        "drive efficiency and innovation.",
      btn: "/Portfolio/websites",
    },
    {
      text:
        "Navigate the social landscape effortlessly with our social media" +
        "management services. We help you build and maintain a strong online" +
        "presence, connect with your audience, and leverage social platforms" +
        "to boost your brand.",
      btn: "/Portfolio/graphics",
    },
  ];
  const btn = [
    { text: "Graphics", txtbold: "design" },
    { text: "web", txtbold: "development" },
    { text: "Software", txtbold: "development" },
    { text: "socailmedia", txtbold: "management" },
  ];
  return (
    <div className="flex flex-row justify-center items-center px-6 py-6">
      <div className="rounded-full">
        <img src={logo} alt="" className="w-96 shadow p-5 rounded-full" />
      </div>
      <div className="space-y-10 flex flex-col justify-start items-start">
        <div className="space-y-9">
          <div className="border w-40 border-primary -rotate-[30deg]" />
          <div className="border w-36 border-primary -rotate-12" />
        </div>
        <div className="space-y-9">
          <div className="border w-36 border-primary rotate-12" />
          <div className="border w-40 border-primary rotate-[30deg]" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center space-y-10">
        {btn.map((items, index) => (
          <button
            onClick={() => handleclick(index)}
            key={index}
            className="text-xs uppercase bg-white px-5 py-1 rounded-full hover:bg-Secondary duration-150 ease-in-out hover:shadow-lg hover:shadow-[#a8a8a86b]">
            {items.text} <span className="font-bold">{items.txtbold}</span>
          </button>
        ))}
      </div>
      {desc && selectedService !== null && (
        <div
          onClick={handleclose}
          className="fixed bg-black bg-opacity-40 w-screen h-screen top-0 right-0 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg px-4 py-3 w-96 space-y-4">
            <div className="">
              <IoIosCloseCircleOutline
                onClick={handleclose}
                className="text-3xl hover:text-Secondary"
              />
            </div>
            <div className="flex flex-col justify-end items-end space-y-2">
              {" "}
              <p>{service[selectedService].text}</p>
              <Link
                to={service[selectedService].btn}
                className="text-md bg-primary rounded-full px-6 py-1 text-white hover:bg-Secondary duration-150 ease-in-out">
                Our work
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceListMobile;
