import demii from '../Assets/website-portfolio/Demii.PNG'
import maed from '../Assets/website-portfolio/Maed.PNG'
import blue from '../Assets/website-portfolio/Blue.PNG'
import mama from '../Assets/website-portfolio/mama.PNG'

export const websites = [
  {
    name: "Demii Design",
    url: "https://www.demiidesign.com/",
    description: "A website showcasing my portfolio and design services.",
    image: demii,
  },
  {
    name: "Maed Nutritions",
    url: "https://maednutritions.com/",
    description: "A website showcasing my portfolio and design services.",
    image: maed,
  },
  {
    name: "Blue Health Ethiopia",
    url: "https://bluehealthethiopia.com/",
    description: "A website showcasing my portfolio and design services.",
    image: blue,
  },
  {
    name: "Mama's Journey",
    url: "https://www.mamasjourneyethiopia.com/",
    description: "A website showcasing my portfolio and design services.",
    image: mama,
  },
];