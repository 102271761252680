import React from 'react'


const Counter = ({value, placeholder, text,icon,grid}) => {
  return (
    <div className={`flex justify-center items-center gap-3 ${grid}`} >
      <div className='text-2xl text-primary'>{icon}</div>
      <div>
        <div className="w-12 h-1 bg-Dark-primary md:my-2 my-1" />
        <div>
          <h1 className="text-2xl font-bold">
            {value}+ {placeholder}
          </h1>
          <p className="text-sm tracking-wide">{text}</p>
        </div>
      </div>
    </div>
  );
}

export default Counter
