import React from 'react'
import { AiTwotoneEye } from "react-icons/ai";
import { GiStairsGoal } from "react-icons/gi";
import { MdDiamond } from "react-icons/md";

const AboutTilet = () => {
  return (
    <div className="mt-8 md:px-12 px-6 md:text-start text-center">
      <div className="w-full h-[1px] bg-Dark-primary" />
      <div className="my-9 w-full flex md:flex-row flex-col items-center">
        <div className="md:w-1/2 w-full">
          <p className="leading-9 capitalize tracking-wide font-light">
            <span className="font-bold text-3xl relative">
              Tilet Tech{" "}
              <span className="w-20 h-10 bg-Secondary absolute -z-20 left-[4.6rem]" />{" "}
            </span>{" "}
            is a youthful software development company founded by a passionate
            group eager to introduce the latest innovations to the market. We
            specialize in creating cutting-edge software solutions tailored to
            diverse industries. Our team's forward-thinking approach and
            commitment to staying at the forefront of technology drive us to
            craft impactful, user-centric applications. Through collaboration
            and a culture of continuous learning, we aim to revolutionize the
            tech landscape and make a lasting mark in the industry.
          </p>
        </div>
        <div className="md:h-80 h[1px] md:w-[1px] w-full bg-Dark-primary md:mx-5 my-5" />
        <div className="md:w-1/2 w-full flex md:items-start items-center flex-col">
          <h1 className="font-semibold text-Secondary text-lg tracking-widest">
            <span className="flex items-center gap-2">
              <AiTwotoneEye className="text-2xl text-primary" /> VISION
            </span>
          </h1>
          <p className="text-sm font-light leading-relaxed w-3/4">
            To be a global technology leader, driving innovation and shaping the
            future we empower through transformative solutions, faster
            partnerships, and make a positive impact worldwide.
          </p>
          <div className="w-full h-[1px] my-3 bg-Dark-primary" />
          <h1 className="font-semibold text-Secondary text-lg tracking-widest">
            <span className="flex items-center gap-2">
              {" "}
              <GiStairsGoal className="text-2xl text-primary" /> MISSION
            </span>
          </h1>
          <p className="text-sm font-light leading-relaxed w-3/4">
            Our mission to empower through technology we deliver innovation
            solutions, build lasting relationships , and drive growth. Together,
            we redefine possibilities and make a meaning ful impact.
          </p>
          <div className="w-full h-[1px] my-3 bg-Dark-primary" />
          <h1 className="font-semibold text-Secondary text-lg tracking-widest">
            <span className="flex items-center gap-2">
              <MdDiamond className="text-2xl text-primary" /> VALUE
            </span>
          </h1>
          <p className="text-sm font-light leading-relaxed w-3/4">
            <ul>
              <li>INNOVATION</li>
              <li>EXCELLENCE</li>
              <li>COLLABRATION</li>
            </ul>
          </p>
        </div>
      </div>
      <div className="w-full h-[1px] bg-Dark-primary" />
    </div>
  );
}

export default AboutTilet
