import Counter from '../../Component/Counter/Counter';
import { FaRegCalendarCheck } from "react-icons/fa6";
import { PiProjectorScreenChartFill } from "react-icons/pi";
import { BsPersonVideo } from "react-icons/bs";
import React from 'react'

const WhatWeAchieved = () => {
  return (
    <div className="flex md:flex-row flex-col md:my-28 my-10 gap-20 items-center md:px-12 px-6">
      <h1 className="md:text-left text-center text-2xl capitalize tracking-wider leading-relaxed">
        What we have achieved <br />
        <span className="font-bold">So far?</span>
      </h1>
      <div className="md:flex grid  grid-cols-2 justify-center items-center md:gap-40 gap-3">
        <Counter
          icon={<FaRegCalendarCheck />}
          value={4}
          placeholder={"Years"}
          text={"Exprience"}
          grid={""}
        />
        <Counter
          icon={<PiProjectorScreenChartFill />}
          value={20}
          placeholder={"Projects"}
          text={"Projects delivered"}
          grid={""}
        />
        <Counter
          icon={<BsPersonVideo />}
          value={10}
          placeholder={"Clients"}
          text={"Clients working with us"}
          grid={"col-span-2"}
        />
      </div>
    </div>
  );
}

export default WhatWeAchieved
