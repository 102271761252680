import React, { useState } from "react";
import telegram from "../../Assets/icons/Telegram.png";
import instagram from "../../Assets/icons/Instagram.png";
import linkedin from "../../Assets/icons/Linkedin.png";

const AboutUsCard = ({
  image,
  name,
  title,
  titleDesc,
  link1,
  link2,
  link3,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="w-full md:w-80 bg-lightergray md:h-96 h-full flex flex-col justify-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      
      <div
        className={`absolute space-y-2 -top-32 md:-right-48 right-0 w-full transition-all duration-300 ease-in-out z-10 ${
          isHovered ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <h1 className="font-black text-2xl w-full">{name}</h1>
        <p>
          {title}
          <span className="text-xs font-bold tracking-wide uppercase">
            {" "}
            {titleDesc}
          </span>
        </p>
        <div className="text-xs w-full md:w-1/2 flex items-start justify-start gap-4">
          <a href={link1} target="_blank" rel="noreferrer">
            <img className="w-6 hover:w-8 duration-300" src={telegram} alt="" />
          </a>
          <a href={link2}>
            <img
              className="w-6 hover:w-8 duration-300"
              src={instagram}
              alt=""
            />
          </a>
          <a href={link3} target="_blank" rel="noreferrer">
            <img className="w-6 hover:w-8 duration-300" src={linkedin} alt="" />
          </a>
        </div>
      </div>
      <div className="w-full h-[80%] transition-all duration-500 cursor-pointer relative aspect-square">
        <img
          className={`object-contain w-full transition-all duration-500 ease-in-out z-20 ${
            isHovered
              ? "-translate-y-56 md:-translate-x-16 md:h-[140%] h-[115%]"
              : "md:translate-y-12 md:h-full"
          }`}
          src={image}
          alt=""
          style={{
            maskImage: "linear-gradient(to top, transparent, black 50%)",
          }}
        />
      </div>
    </div>
  );
};

export default AboutUsCard;
