import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import PortfolioNavigation from "../../Component/PortfolioNavigation/PortfolioNavigation";
import { graphics } from "../../data/graphics_portfolio";

const Graphics = () => {
const [selectedImage, setSelectedImage] = useState();
const [photoIndex, setPhotoIndex] = useState();

useEffect(() => {
  window.scroll(0, 0);
}, []);

useEffect(() => {
  const body = document.querySelector("body");
  if (selectedImage) {
    body.style.overflow = "hidden"; // Disable scrolling
  } else {
    body.style.overflow = ""; // Enable scrolling
  }
  return () => {
    // Cleanup: Re-enable scrolling when component unmounts
    body.style.overflow = "";
  };
}, [selectedImage]);

const allImages = graphics.reduce((acc, archive) => {
  acc.push(...archive.image);
  return acc;
}, []);

  return (
    <div className="my-32 px-12">
      <PortfolioNavigation />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {graphics.map((graphic, index) => (
          <React.Fragment key={graphic.id}>
            <div key={index} className="overflow-hidden">
              <img
                src={graphic.image}
                alt={graphic.title}
                loading="lazy"
                className="w-full h-full object-cover transform transition-transform duration-500 scale-95 hover:scale-110"
                onClick={() => {
                  setSelectedImage(graphic.image);
                  setPhotoIndex(allImages.indexOf(graphic.image));
                }}
              />
            </div>
          </React.Fragment>
        ))}
        {selectedImage && (
          <Lightbox
            key={selectedImage}
            imageTitle={"Collection For "}
            mainSrc={selectedImage}
            nextSrc={allImages[(photoIndex + 1) % allImages.length]}
            prevSrc={
              allImages[(photoIndex + allImages.length - 1) % allImages.length]
            }
            onCloseRequest={() => setSelectedImage(null)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + allImages.length - 1) % allImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % allImages.length)
            }
          />
        )}
      </div>
    </div>
  );
};

export default Graphics;
