import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import whitelogo from "../../Assets/logo/white logo only.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-scroll";
import {
  FaTelegramPlane,
  FaRegCopyright,
  FaFacebook,
  FaTiktok,
} from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

const Footer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jn2696h",
        "template_7dfhz1l",
        form.current,
        "cbQ54ZS5LwpvhfcJa"
      )
      .then(
        (result) => {
          toast.success("Your email has been sent successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          form.current.reset();
        },
        (error) => {
          toast.error("Your email is unsuccessfull check your connection!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
  };

  const links = [
    { link: "HOME", to: "Home" },
    { link: "ABOUT US", to: "About us" },
    { link: "PORTFOLIO", to: "Portfolio" },
    { link: "SERVICE", to: "Service" },
  ];
  const icons = [
    {
      icon: RiInstagramFill,
      link: "https://instagram.com/tilettech?igshid=YmMyMTA2M2Y=",
    },
    {
      icon: FaTiktok,
      link: "https://www.tiktok.com/@tilettech?_t=8af7c6RORl7&_r=1",
    },
    { icon: FaTelegramPlane, link: "https://t.me/tilettech" },
    { icon: FaFacebook, link: "https://facebook.com/tilettechofficial" },
  ];

  return (
    <div className="flex flex-col justify-center items-center w-full bg-gray md:px-12 px-6 py-4 space-y-7 text-white">
      <div className="flex md:flex-row flex-col md:justify-between justify-center items-center md:space-y-0 space-y-4 w-full">
        <img src={whitelogo} alt="" className="md:w-16 w-14" />
        <div className="flex flex-row justify-center items-cente font-light md:text-sm text-xs space-x-12">
          {links.map((items,index) => (
            <Link
              key={index}
              to={items.to}
              smooth={true}
              duration={1000}
              className="hover:text-Secondary duration-150 ease-in-out cursor-pointer">
              {items.link}
            </Link>
          ))}
        </div>
        <div className="flex flex-row justify-center items-center text-xl md:space-x-2 space-x-4">
          {icons.map((items,index) => (
            <a
              key={index}
              href={items.link}
              className="hover:text-Secondary duration-150 ease-in-out">
              <items.icon />
            </a>
          ))}
        </div>
      </div>

      <div className="border w-full border-lightgray" />

      <div className="flex flex-row justify-center items-center space-x-4 text-sm p-3">
        <form
          className="flex flex-col justify-center items-center rounded-sm space-y-4"
          ref={form}
          onSubmit={sendEmail}>
          <input
            type="email"
            name="email"
            placeholder="ENTER EMAIL HERE"
            className="p-2 bg-lightgray outline-none w-80 "
          />
          <textarea
            name="message"
            id=""
            cols="10"
            rows="7"
            placeholder="TYPE MESSAGE HERE..."
            className="p-2 bg-lightgray outline-none w-80 "></textarea>
          <button type="submit" className="w-80 bg-Secondary font-semibold text-lightgray hover:text-white p-1 hover:bg-Dark-primary duration-150 ease-in-out">
            SEND EMAIL
          </button>
          <ToastContainer />
        </form>
      </div>

      <p className="flex flex-row justify-center items-center text-xs text-lightergray">
        <FaRegCopyright /> Copyright reserved 2023
      </p>
    </div>
  );
};

export default Footer;
