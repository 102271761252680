import React from 'react'
import arrow from '../../Assets/ARROW2.png'
import { Pagination } from "swiper/modules";
import { Element } from 'react-scroll';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import AboutUsCard from '../../Component/AboutUsCard/AboutUsCard';
import robi from '../../Assets/Team/Robi.png'
import wase from '../../Assets/Team/Wase.png'
import basi from '../../Assets/Team/Bas.png'
import beki from '../../Assets/Team/Beki.png'
import bam from '../../Assets/Team/Bam.png'
import nuha from '../../Assets/Team/Nuha.png'

const AboutUs = () => {
  const teams = [
    {
      image: robi,
      name: "Rehbote Alemayehu",
      title: "CEO",
      titledesc: "(Chief Executive Officer)",
      instagram:
        "https://www.instagram.com/robisha_rj?igsh=M3Ixc2NubGQ2Nmdi&utm_source=qr",
      telegram: "https://t.me/RobishaRJ",
      linkedin: "https://www.linkedin.com/in/rehbote-alemayhu-7139b6246",
    },
    {
      image: wase,
      name: "Wasyhun H/melekot",
      title: "COO",
      titledesc: "(Chief Operation Officer)",
      instagram: "https://www.instagram.com/wasehaille",
      telegram: "https://t.me/Wase_Haile",
      linkedin:
        "https://www.linkedin.com/in/wase-haile-223940249?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
      image: basi,
      name: "Basliel Bedewi",
      title: "CTO",
      titledesc: "(Chief Technology Officer)",
      instagram:
        "https://www.instagram.com/baslielbedewi?igsh=NW1zYXdjY2dvbDU1",
      telegram: "https://t.me/BasiBedewi",
      linkedin:
        "https://www.linkedin.com/in/basliel-bedewi-a66891266?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
      image: beki,
      name: "Bereket Yirga",
      title: "CDO",
      titledesc: "(Chief Data Officer)",
      instagram:
        "https://www.instagram.com/beckyyirga?igsh=MXR2ZWNuNDZmaTd1dA==",
      telegram: "https://t.me/Becky_Yirga",
      linkedin:
        "https://www.linkedin.com/in/bereket-yirga-07a553208?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
      image: bam,
      name: "Bamlak Seyoum",
      title: "SMM",
      titledesc: "(Social Media Manager)",
      instagram: "https://www.instagram.com/bamlakseyoum?igsh=Mno0YmJiYzhjYm0z",
      telegram: "https://t.me/Bam9719",
      linkedin:
        "https://www.linkedin.com/in/bamlak-seyoum-30455b231?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
      image: nuha,
      name: "Nuhamin Abera",
      title: "CFO",
      titledesc: "(Chief Finance Officer)",
      instagram: "https://www.instagram.com/Greypillars",
      telegram: "https://t.me/Greypillar93",
      linkedin:
        "https://www.linkedin.com/in/nuhamin-abera-b73533225?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
  ];
  return (
    <Element name="About us" className="flex flex-col items-start gap-10 md:px-12 px-6">
      <div className="w-full h-[1px] bg-Dark-primary" />
      <div className='gap-48 flex flex-col items-start w-full'>
      <h1 className="uppercase text-3xl flex md:flex-row flex-col md:items-center items-start gap-4">
        <div className='flex items-center gap-3'>
          {" "}
          About <span className="font-bold">Us</span>
          <img
            src={arrow}
            className="w-10 mt-4 duration-150 animate-bounce"
            alt=""
          />
        </div>
        <p className="md:w-1/2 w-full text-base">
          Meet the brilliant minds at Tilet Tech, dedicated to shaping our
          company to meet and exceed high standards.
        </p>
      </h1>
      <div className="flex -mt-48 mb-10 w-full">
        <Swiper
          slidesPerView={1}
          spaceBetween={2}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className=""
        >
          {teams.map((team,index) => (
            <SwiperSlide key={index} className="mt-40 w-full h-auto overflow-visible relative">
              {" "}
              <AboutUsCard
                image={team.image}
                name={team.name}
                title={team.title}
                titleDesc={team.titledesc}
                link1={team.telegram}
                link2={team.instagram}
                link3={team.linkedin}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
    </Element>
  );
}

export default AboutUs
