import React from 'react'

const WhyChooseUsCard = ({title,body,image}) => {
  return (
    <div className="flex md:flex-row flex-col justify-center items-center my-3">
      <div className="bg-Dark-primary md:w-72 w-80 md:h-36 h-80">
        <img
          src={image}
          alt=""
          className="bg-Dark-primary w-full h-full object-cover object-center"
        />
      </div>
      <div className="bg-white flex flex-col justify-center md:w-full w-80 h-36 md:px-8 px-4">
        <h1 className="font-bold md:text-lg text-md text-Secondary uppercase tracking-widest">
          {title}
        </h1>
        <p className="font-light ms:text-sm text-xs tracking-wider">{body}</p>
      </div>
    </div>
  );
}

export default WhyChooseUsCard
