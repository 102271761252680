import React from 'react'
import ImageSlider from '../Component/SocialLinks/ImageSlider';
import { Element } from 'react-scroll';

const Home = () => {
  return (
    <Element name='Home' className="w-full h-screen flex md:flex-row flex-col items-center text-4xl md:pt-0 pt-10 md:px-12 px-6">
      <div className="relative md:w-4/5 w-full">
        <h1 className="md:text-start text-center capitalize text-Dark-primary text-3xl md:leading-[4rem] leading-[3rem] font-extralight tracking-widest md:pt-0 pt-4">
          CREATING AN INTIMATE RELATIONSHIP <br />
          WITH <span className="font-bold relative">TECHNOLOGY</span>
          <span className="absolute md:w-44 w-[100px] md:h-14 h-12 md:left-40 left-48 -z-30 bg-Secondary" />
        </h1>
      </div>
      <div className="md:w-1/2 w-full flex justify-center items-center md:mt-0 mt-8">
        <ImageSlider />
      </div>
    </Element>
  );
}

export default Home