import React, { useEffect } from 'react'
import PortfolioNavigation from '../Component/PortfolioNavigation/PortfolioNavigation';
import Websites from '../Container/Websites/Websites';

const Portfolio = () => {
   useEffect(() => {
     window.scrollTo(0, 0);
   });
  return (
    <div className="my-32 px-12">
      <PortfolioNavigation />
      <Websites />
    </div>
  );
}

export default Portfolio
