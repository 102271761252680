import React from 'react'
import Home from '../Pages/Home'
import PortfolioContainer from '../Container/Portfolio Container/PortfolioContainer'
import AboutTilet from '../Container/About Tilet/AboutTilet'
import WhatWeAchieved from '../Container/What we achieved/WhatWeAchieved'
import WhyChoose from '../Container/WhyChoose/WhyChoose'
import Service from '../Container/Service/Service'
import AboutUs from '../Container/About Us/AboutUs'
import Footer from '../Container/Footer/Footer'

const ScrollRoute = () => {
  return (
    <div>
      <Home />
      <PortfolioContainer />
      <AboutTilet />
      <WhatWeAchieved/>
      <WhyChoose />
      <Service />
      <AboutUs/>
      <Footer/>
    </div>
  )
}

export default ScrollRoute
