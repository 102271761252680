import tilet from '../Assets/graphics_portfolio/tilet.jpg'
import habesh from '../Assets/graphics_portfolio/habesh.jpg'
import Ad from '../Assets/graphics_portfolio/Ad.png'
import post_1 from '../Assets/graphics_portfolio/Post.jpg'
import card from '../Assets/graphics_portfolio/card.jpg'
import brochure from '../Assets/graphics_portfolio/Brochure.jpg'
import post_2 from '../Assets/graphics_portfolio/post-2.jpg'
import banner from '../Assets/graphics_portfolio/banner.jpg'

export const graphics = [
  {
    image: tilet,
    title: "Tilet Tech brand",
    type: "brand"
  },
  {
    image: habesh,
    title: "Habesh Clothing brand",
    type: "brand"
    },
  {
    image:Ad,
    title:"post",
    type:"post"
  },
  {
    image:post_1,
    title:"post",
    type:"post"
  },
  {
    image:card,
    title:"Business card",
    type:"card"
  },
  {
    image:brochure,
    title:"Brochure",
    type:"brochure"
  },
  {
    image:post_2,
    title:"post",
    type:"post"
  },
  {
    image:banner,
    title:"Banner",
    type:"Banner"
  },
];