import React from 'react'
import WhyChooseUsCard from '../../Component/WhychooseusCard/WhyChooseUsCard';
import CreativeDesign from "../../Assets/Why choose Us/Clean design.jpg";
import CuttingEdgeTech from "../../Assets/Why choose Us/high tech.jpg";
import ClientOriented from "../../Assets/Why choose Us/client oriented.jpg";
import { Element } from 'react-scroll';

const WhyChoose = () => {
    const list = [
      {
        id: 1,
        image: CreativeDesign,
        title: "Clean & Catchy Designs",
        body: "Our design philosophy revolves around clean aesthetics that catch the eye. We meticulously craft visuals that are both engaging and uncluttered, ensuring your message shines through.",
      },
      {
        id: 2,
        image: CuttingEdgeTech,
        title: "Cutting-Edge Technology",
        body: "We're not just up-to-date; we're ahead of the curve. By harnessing modern technologies like React JS and Tailwind CSS, we guarantee your digital presence remains innovative and future-proof.",
      },
      {
        id: 3,
        image: ClientOriented,
        title: "Client-Oriented Services",
        body: "At Tilet Tech, we're dedicated to enhancing your digital presence with client-centric services. Discover how our tailored solutions can engage your audience and leave a lasting impression.",
      },
    ];
  return (
    <Element name='Whychoose' className="flex md:flex-row flex-col items-center md:h-full md:px-12 px-6">
      <div className="md:-rotate-90 flex flex-col items-center w-full my-0 justify-center">
        <h1 className="md:text-5xl tracking-widest text-4xl text-slate-800 font-light md:my-0 my-12 text-center">
          WHY CHOOSE
          <span className="font-bold text-Dark-primary">
            <br />
            TILET TECH?
          </span>
        </h1>
      </div>
      <div className="flex flex-col justify-center">
        {list.map((items) => (
          <WhyChooseUsCard
            key={items.id}
            title={items.title}
            body={items.body}
            image={items.image}
          />
        ))}
      </div>
    </Element>
  );
}

export default WhyChoose
