import React, { useEffect, useState } from "react";
import vr from "../../Assets/Imagesforslides/VR.png";
import phone from "../../Assets/Imagesforslides/Phone.png";
import pc from "../../Assets/Imagesforslides/PC.png";

const ImageSlider = () => {
  const image = [{ img: vr }, { img: pc }, { img: phone }];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);

  const nextImage = () => {
    setImageOpacity(0); 
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % image.length);
      setImageOpacity(1); 
    }, 1000);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextImage();
    }, 8000);

    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <div className="relative -z-30">
      <span className="w-44 h-52 rounded-full blur-3xl z-40 left-10 top-20 opacity-100  mix-blend-screen bg-Secondary absolute" />
      <img
        className="w-[30rem] transition-opacity duration-1000 ease-in-out"
        src={image[currentImageIndex].img}
        alt=""
        style={{
          maskImage: "linear-gradient(to top, transparent, black 50%)",
          opacity: imageOpacity,
        }}
      />
    </div>
  );
};

export default ImageSlider;
