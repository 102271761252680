import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation,Autoplay } from 'swiper/modules';
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { portfolio_slides } from "../../data/portfolio_slides";

const PortfolioContainer = () => {
  return (
    <Element name="Portfolio" className="md:px-12 px-6">
      <div className="w-full flex justify-between items-center">
        <h1 className="md:text-lg text-sm">(Addis Ababa, Ethiopia)</h1>
        <Link
          to={"/Portfolio/websites"}
          className="bg-primary hover:bg-Dark-primary duration-150 md:px-7 px-3 tracking-widest flex items-center md:gap-3 gap-2 md:py-2 py-1 text-white md:text-sm text-xs rounded-full"
        >
          View Portfolio
          <IoArrowForwardCircleOutline className="text-2xl" />
        </Link>
      </div>
      <div className="w-full h-[24rem]  bg-Dark-primary mt-5">
        <Swiper
          loop={true}
          centeredSlides={true}
          navigation={true}
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 5500,
          }}
          className="mySwiper h-[24rem] text-white w-full"
        >
          {portfolio_slides.map((slides) => (
            <SwiperSlide>
              <Link to={slides.link} target="_blank">
                <img
                  className="w-full h-full object-cover"
                  src={
                    window.innerWidth <= 768 ? slides.mobileImage : slides.image
                  }
                  alt=""
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Element>
  );
};

export default PortfolioContainer;
