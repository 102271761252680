import Navigation from "./Component/Navigation/Navigation";
import Graphics from "./Container/Graphics/Graphics";
import Websites from "./Container/Websites/Websites";
import Portfolio from "./Pages/Portfolio";
import ScrollRoute from "./Route/ScrollRoute";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div>
      <Navigation />
      <Routes>
        <Route exact path="/" element={<ScrollRoute />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Portfolio/graphics" element={<Graphics />} />
        <Route path="/Portfolio/websites" element={<Websites />} />
      </Routes>
    </div>
  );
}

export default App;

