import React, { useState } from "react";
import logo from "../../Assets/logo/black logo only.png";
import { useNavigate } from "react-router-dom";

const ServiceList = () => {
  const navigate = useNavigate()
  const [isHover, setisHover] = useState(false);
  const [isHover1, setisHover1] = useState(false);
  const [isHover2, setisHover2] = useState(false);
  const [isHover3, setisHover3] = useState(false);

  return (
    <div className="flex flex-col justify-start items-center mb-12">
      {isHover && (
        <div className="flex justify-start items-start p-2 left-12 absolute mt-9 z-50">
          <p className="w-80 p-3 bg-white rounded-3xl shadow-2xl rounded-tr-none">
            Captivate your audience with visually stunning designs that convey
            your brand's essence. Our talented graphic designers bring
            creativity and expertise to every project, ensuring your visuals
            leave a lasting impression.
          </p>
        </div>
      )}
      {isHover1 && (
        <div className="flex justify-start items-start p-2 right-12 mt-9 absolute z-50">
          <p className="w-80 p-3 bg-white rounded-3xl shadow-2xl rounded-tl-none">
            Step into the digital realm with our expert website development
            services. From sleek and responsive designs to seamless user
            experiences, we craft websites that reflect your brand identity and
            engage your audience effectively.
          </p>
        </div>
      )}
      <div className=" flex flex-row justify-start items-start space-x-72 mb-16">
        <button
          onClick={() => navigate("/Portfolio/graphics")}
          onMouseEnter={() => setisHover(true)}
          onMouseLeave={() => setisHover(false)}
          className={`bg-white px-5 py-1 rounded-full hover:bg-Secondary duration-150 ease-in-out hover:shadow-lg hover:shadow-[#a8a8a86b]`}>
          GRAPHICS <span className="font-bold">DESIGN</span>
        </button>
        <button
          onClick={() => navigate("/Portfolio/websites")}
          onMouseEnter={() => setisHover1(true)}
          onMouseLeave={() => setisHover1(false)}
          className="bg-white px-5 py-1 rounded-full hover:bg-Secondary duration-150 ease-in-out hover:shadow-lg hover:shadow-[#a8a8a86b]">
          WEB <span className="font-bold">DEVELOPMENT</span>
        </button>
      </div>
      <div className="space-x-64 mb-9">
        <div className="border rotate-45 w-44 h-0 border-primary" />
        <div className="border -rotate-45 w-44 border-primary" />
      </div>
      <div className="rounded-full shadow px-6 py-10">
        <img src={logo} alt="" className="w-20" />
      </div>
      <div className="space-x-64 mt-9">
        <div className="border -rotate-45 w-44 border-primary" />
        <div className="border rotate-45 w-44 border-primary" />
      </div>
      <div className=" flex flex-row justify-start items-start space-x-72 mt-16">
        <button
          onClick={() => navigate("/Portfolio/websites")}
          onMouseEnter={() => setisHover2(true)}
          onMouseLeave={() => setisHover2(false)}
          className="bg-white px-5 py-1 rounded-full hover:bg-Secondary duration-150 ease-in-out hover:shadow-lg hover:shadow-[#a8a8a86b]">
          SOFTWARE <span className="font-bold">DEVELOPMENT</span>
        </button>
        <button
          onClick={() => navigate("/Portfolio/graphics")}
          onMouseEnter={() => setisHover3(true)}
          onMouseLeave={() => setisHover3(false)}
          className="bg-white px-5 py-1 rounded-full hover:bg-Secondary duration-150 ease-in-out hover:shadow-lg hover:shadow-[#a8a8a86b]">
          SOCIAL-MEDIA <span className="font-bold">MANAGEMENT</span>
        </button>
      </div>
      {isHover2 && (
        <div className="flex justify-start items-start p-2 left-10 mt-36 absolute z-50">
          <p className="w-80 p-3 bg-white rounded-3xl shadow-2xl rounded-br-none">
            Empower your business with custom software solutions tailored to
            your unique requirements. Our skilled developers leverage the latest
            technologies to build robust and scalable software applications that
            drive efficiency and innovation.
          </p>
        </div>
      )}
      {isHover3 && (
        <div className="flex justify-start items-start p-2 right-10 mt-36 absolute z-50">
          <p className="w-80 p-3 bg-white rounded-3xl shadow-2xl rounded-bl-none">
            Navigate the social landscape effortlessly with our social media
            management services. We help you build and maintain a strong online
            presence, connect with your audience, and leverage social platforms
            to boost your brand.
            <br />
          </p>
        </div>
      )}
    </div>
  );
};

export default ServiceList;