import React, { useEffect, useState } from "react";
import Arrow from "../../Assets/ARROW.png";
import ServiceList from "../../Component/ServiceList/ServiceList";
import { Element } from "react-scroll";
import ServiceListMobile from "../../Component/ServiceList/ServiceListMobile";

const Service = () => {
  const [responsive, setResponsive] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      setResponsive(window.innerWidth < 768);
    };

    // Initial check on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
      
  
  return (
  
    <Element name='Service' className="flex flex-col justify-center items-center space-y-6 py-8 md:h-full h-full">
      <div className="flex flex-row justify-center items-center space-x-5">
        <img src={Arrow} alt="" className="md:w-7 w-5 duration-150 animate-bounce " />
        <h1 className="md:text-5xl text-4xl font-light">
          OUR <span className="font-bold">SERVICE</span>
        </h1>
      </div>
      <div className="flex justify-center items-center ">

          {responsive ? <ServiceListMobile /> : <ServiceList />}
          
      </div>
      </Element>
  
  );
};

export default Service;
