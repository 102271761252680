import demi from "../Assets/Portfolio slide/Demii.jpg";
import maed from "../Assets/Portfolio slide/Maed.jpg";
import blue from "../Assets/Portfolio slide/Blue.jpg";
import mama from "../Assets/Portfolio slide/Mama.jpg";
import grph from "../Assets/Portfolio slide/Grph.jpg";
import demiimobile from "../Assets/Portfolio slide/Demii-mobile.jpg";
import maedmobile from "../Assets/Portfolio slide/Maed mobile.jpg";
import bluemobile from "../Assets/Portfolio slide/Blue mobile.jpg";
import mamamobile from "../Assets/Portfolio slide/Mama mobile.jpg";
import grphmobile from "../Assets/Portfolio slide/grph mobile.jpg";

export const portfolio_slides = [
  {
    title: "demii website",
    image: demi,
    mobileImage: demiimobile,
    link: "https://www.demiidesign.com/",
  },
  {
    title: "maed website",
    image: maed,
    mobileImage: maedmobile,
    link: "https://maednutritions.com/",
  },
  {
    title: "bluehealth website",
    image: blue,
    mobileImage: bluemobile,
    link: "https://bluehealthethiopia.com/",
  },
  {
    title: "mamas journey website",
    image: mama,
    mobileImage: mamamobile,
    link: "https://www.mamasjourneyethiopia.com/",
  },
  {
    title: "graphics",
    image: grph,
    mobileImage: grphmobile,
    link: "Portfolio/graphics",
  },
];
